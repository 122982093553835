import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "240",
  height: "240",
  viewBox: "0 0 230 258"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M396.21 644.575c33.772-7.246 63.813-29.334 80.466-59.162 19.489-34.906 20.498-76.665 2.727-112.757-6.578-13.36-13.593-22.887-24.525-33.306-14.872-14.174-32.979-24.306-52.9-29.6-14.259-3.789-36.832-4.702-50.891-2.058-46.605 8.762-84.082 43.535-95.945 89.024-2.945 11.293-4.059 36.372-2.147 48.344 7.887 49.392 46.03 89.206 95.455 99.638 9.635 2.033 38.05 1.96 47.76-.123",
      style: {"opacity":".98999999","color":"#000","fill":"#ff6a10","fill-opacity":"1","fill-rule":"evenodd","stroke":"none","stroke-width":"0","marker":"none","visibility":"visible","display":"inline","overflow":"visible"},
      transform: "translate(-252.047 -406.181)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M358.73 480.17c-47.029-24.018-82.55 16.204-80.54 46.443.204 38.153 41.28 66.44 80.54 45.772v47.911l25.184-.092.399-48.011c49.498 23.343 82.254-16.035 81.665-45.569-.607-38.094-38.745-67.743-81.665-46.455v-48.103h-24.857zm-26.611 18.1c40.276 4.507 32.868 55.81-.148 56.59-38.905-.958-38.806-56.596.148-56.59m82.561-.804c37.467 4.216 33.126 56.358-1.778 57.396-42.839-5.613-34.106-58.421 1.778-57.396",
      style: {"opacity":".98999999","color":"#000","fill":"#fff","fill-opacity":"1","fill-rule":"evenodd","stroke":"none","stroke-width":"0","marker":"none","visibility":"visible","display":"inline","overflow":"visible"},
      transform: "translate(-252.047 -406.181)"
    }, null, -1)
  ])))
}
export default { render: render }